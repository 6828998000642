import React, {useState} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import imgPage from "../images/headers/Logos_Ale_Service_Center_Blue.svg"
import Header from "../components/header";
import Img from "gatsby-image"
import style from "./service.module.scss"

import mantenimiento from "../images/service/mantenimiento-icono.png"
import pintura1 from "../images/service/pintura-icono.png"
import pintura2 from "../images/service/pintura2-icono.png"
import avionica from "../images/service/avionica-icono.png"
import control from "../images/service/control.png"

import beechcraft from "../images/service/logo_Textron.png"
import raisbeck from "../images/service/Logo-Raisbeck.png"
import Blackhawk from "../images/service/Logo-Blackhawk.png"
import honey from "../images/service/honeywell-logo.png"
import pratt from "../images/service/pratt-logo.png"
import leonardo from "../images/service/leonardo-logo.png"
import rock from "../images/service/rockwell-logo.png"


import ReactWOW from "react-wow";
import Form from "../components/form";

export default ({data}) => {

    const [slide, setSlide] = useState(1);

    function goToSlide(x) {
        setSlide(x)
    }

    const extraInfo =
        [
            {name: 'Ventas', mail: 'ventas@alesc.mx', phone: '722 279 1650'},
            {name: 'Atención a Clientes', mail: 'cpm@alesc.mx', phone: ''}
        ];

    return (
        <Layout extraInfo={extraInfo}>
            <SEO title="Service Center"/>
            <Header LogoPage={imgPage}/>

            <div className={style.serviceCover}>
                <Img fluid={data.portada.childImageSharp.fluid}/>
            </div>

            <Form options={['Ale Service Center: Workshop']}/>

            <ReactWOW animation='fadeIn'>
                <div className={style.serviceInfo}>
                    <h1>Service Center</h1>
                    <p>Ale Service Center se ha especializado en brindar un servicio de excelencia para aeronaves Hawker
                        y Beechcraft, Leonardo helicópteros y Textron Aviation.</p>
                    <p>Somos el único Centro de Servicio en México autorizado por Textron Aviation para aeronaves
                        Hawker, Beechcraft y Cessna; Además de contar con la representación exclusiva para helicópteros
                        Leonardo en los modelos Agusta 109, 119, y AW 139.</p>
                </div>
            </ReactWOW>


            <div className={style.serviceOur}>
                <h2>Nuestros Servicios</h2>
                <div className={style.services}>

                    <div className={style.service}>
                        <div className={style.img}>
                            <img src={mantenimiento} alt="Mantenimiento"/>
                        </div>
                        <p><b>Mantenimiento</b></p>
                        <p>Ampliamos nuestras capacidades determinados a exceder sus expectativas.</p>
                    </div>

                    <div className={style.service}>
                        <div className={style.img}>
                            <img src={pintura1} alt="Pintura"/>
                        </div>
                        <div className={style.img}>
                            <img src={pintura2} alt="Pintura"/>
                        </div>
                        <p><b>Pintura e Interiores</b></p>
                        <p>Combinamos instalaciones de primera con la mejor mano de obra para brindarle un servicio de
                            alta calidad técnica.</p>
                    </div>

                    <div className={style.service}>
                        <div className={style.img}>
                            <img src={avionica} alt="Aviónica"/>
                        </div>
                        <p><b>Aviónica</b></p>
                        <p><span>ALE Service Center</span> encabeza la industria en experiencia de instalaciones dentro de la aviación ejecutiva en México. Las actualizaciones de aviónica incrementan la seguridad, el desempeño y valor de su aeronave.</p>
                    </div>

                    <div className={style.service}>
                        <div className={style.img}>
                            <img src={control} alt="Mantenimiento"/>
                        </div>
                        <p><b>Control del mantenimiento</b></p>
                        <p>Con la más amplia experiencia, administramos el control de mantenimiento en más de 120
                            aeronaves, bajo contrato registrado ante la DGAC.</p>
                    </div>

                    <div className={style.more}>
                        <a href="http://aleservicecenter.com/" target="_blank" rel="noopener noreferrer">Conozca Más</a>
                    </div>

                </div>
            </div>

            <div className={style.serviceBrands}>
                <h2>Centro de servicio autorizado</h2>
                <p><b>También somos el centro de mantenimiento de nuestra flota</b></p>
                <div className={style.items}>
                    <div className={style.item}>
                        <a href="https://beechcraft.txtav.com/" target='_blank' rel="noopener noreferrer">
                            <img src={beechcraft} alt="Beechcraft"/>
                        </a>
                        <p><b>Beechcraft</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://raisbeck.com/" target='_blank' rel="noopener noreferrer">
                            <img src={raisbeck} alt="Raisbeck"/>
                        </a>
                        <p><b>Raisbeck</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://www.blackhawk.aero/" target='_blank' rel="noopener noreferrer">
                            <img src={Blackhawk} alt="Blackhawk"/>
                        </a>
                        <p><b>Blackhawk</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://aerospace.honeywell.com/en" target='_blank' rel="noopener noreferrer">
                            <img src={honey} alt="Honeywell"/>
                        </a>
                        <p><b>Honeywell</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://www.pw.utc.com/" target='_blank' rel="noopener noreferrer">
                            <img src={pratt} alt="Pratt & Whitney"/></a>
                        <p><b>Pratt & Whitney</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://www.leonardocompany.com/en/home/" target='_blank' rel="noopener noreferrer">
                            <img src={leonardo} alt="Leonardo"/></a>
                        <p><b>Leonardo</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://www.rockwellcollins.com/" target='_blank' rel="noopener noreferrer">
                            <img src={rock} alt="Textron"/></a>
                        <p><b>Rockwell Collins</b></p>
                    </div>

                </div>
            </div>


            <div className={style.serviceTitle}>
                <p>Capacidades de Mantenimiento</p>
                <span> </span>
            </div>

            <div className={style.serviceCapacities}>
                <div className={style.bullets}>
                    <button className={slide === 1 ? `${style.active}` : ''} onClick={(e) => goToSlide(1)}>
                    </button>
                    <button className={slide === 2 ? `${style.active}` : ''} onClick={(e) => goToSlide(2)}>
                    </button>
                    <button className={slide === 3 ? `${style.active}` : ''} onClick={(e) => goToSlide(3)}>
                    </button>
                </div>
                <div className={slide === 1 ? `${style.active}` : ''}>
                    <p><b>Citation M2</b></p>
                    <p><b>Citation CJ2, CJ3, CJ4</b></p>
                    <p><b>Citation Excel</b></p>
                    <p><b>Citation XL, XLS, XSL+</b></p>
                    <p><b>Citation Sovereign</b></p>
                    <p><b>Citation Latitude</b></p>
                </div>
                <div className={slide === 2 ? `${style.active}` : ''}>
                    <p><b>King Air 90 Series</b></p>
                    <p><b>King Air 200 Series</b></p>
                    <p><b>King Air 350 Series</b></p>
                    <p><b>Challenger 604, 605, 650</b></p>
                    <p><b>Honda HA-420</b></p>
                    <p><b>Leonardo Helicopters</b></p>
                </div>
                <div className={slide === 3 ? `${style.active}` : ''}>
                    <p><b>Hawker 750/800/900</b></p>
                    <p><b>Hawker 4000</b></p>
                    <p><b>Beechjet / 400XP</b></p>
                    <p><b>Premier 1/1A</b></p>
                    <p><b>Beech Piston</b></p>
                    <p><b>Learjet 40/45/60/70/75</b></p>
                </div>
            </div>


            <div className={style.serviceImages}>
                <Img fluid={data.foto1.childImageSharp.fluid}/>
                <div className={style.title}>
                    <p>Pintura e Interiores</p>
                    <span> </span>
                </div>

                <div className={style.infoBlock}>
                    <Img className={style.img} fluid={data.foto2.childImageSharp.fluid}/>
                    <div className={style.info}>
                        <div className={style.ul}>
                            <p>Combinamos instalaciones de primera con la mejor mano de obra para brindarle un servicio
                                de
                                calidad, siendo la mejor opción para renovar la imagen de su aeronave. Nuestro hangar
                                cumple
                                con las más estrictas normas de calidad y protección al ambiente de la FAA y EASA
                                (Agencia
                                Europea de Seguridad Aérea). *TALLER CERTIFICADO POR: FAA: UQXZ990X y DGAC: 253.</p>
                            <p>¡Renovamos su aeronave! contamos con una amplia gama de modelos, tonalidades y diseños en
                                materiales como pieles, alfombras, ultra-leather, telas, platinado, persianas, webbing,
                                entre otros. Los materiales que utilizamos están certificados bajo la Norma 25 del FAR
                                de la
                                FAA.</p>
                        </div>
                    </div>
                </div>

                {/*<div className={style.title}>
                    <p>Lorem Ipsum</p>
                    <span> </span>
                </div>*/}

                {/*<div className={style.infoBlock}>
                    <div className={style.info}>
                        <div className={style.ul}>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus at aut eligendi ex
                                fugit illo labore magnam magni minima necessitatibus nostrum odit, quasi quos ut
                                veritatis
                                voluptates. In, maxime?</p>
                        </div>
                    </div>
                    <img src={Foto3} alt="Lorem"/>
                </div>*/}

                <div className={style.title}>
                    <p>Modificaciones / Upgrades</p>
                    <span> </span>
                </div>

                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <div className={style.ul}>
                            <p><b>Aviónica</b></p>
                            <p>Somos el Centro de Servicio Autorizado de Rockwell Collins para pruebas e instalaciones; y de Honeywell a nivel línea.</p>
                        </div>
                    </div>
                    <Img className={style.img} fluid={data.foto3.childImageSharp.fluid}/>

                </div>
            </div>

        </Layout>
    )

}


export const query = graphql`
    query ImagesCenter {
    portada: file(relativePath: {eq: "service/portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto1: file(relativePath: {eq: "service/foto1.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2: file(relativePath: {eq: "service/foto2.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto3: file(relativePath: {eq: "service/foto3.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`;





